/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Heading,
  Image,
  Stack,
  Text,
  List,
  ListItem,
  HStack,
  Link as ChakraLink,
} from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { motion, useScroll, useSpring } from 'framer-motion'

import { useGetPostDetailQuery } from '../services/blog-query.service'
import { PostDetailLoader } from '@/education/shared/components/loaders/PostDetailLoader'
import { Button, NucArrowLeft } from '@nucba-coding/nucba-ui'

export const Post = () => {
  const navigate = useNavigate()
  const { postSlug, spaceSlug } = useParams<{
    postSlug: string
    spaceSlug: string
  }>()

  const { data, isLoading } = useGetPostDetailQuery({
    spaceSlug: spaceSlug!,
    postSlug: postSlug!,
  })

  const { scrollYProgress } = useScroll()
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  })

  const post = data?.posts.at(0)
  if (isLoading) return <PostDetailLoader />

  if (!post)
    return (
      <HStack
        w='full'
        h='100vh'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        gap={2}
      >
        <Text>La nota que estabas buscando no existe</Text>
        <Button size='sm'>
          <Link to='/blog'>Ir al inicio</Link>
        </Button>
      </HStack>
    )

  const processedMarkdown = post.body
    .replace(/(.*?)\n-{3,}\n/g, (match, title) => {
      return `## ${title.trim()}\n`
    })
    .replace(/-{4,}/g, '<hr />')

  return (
    <>
      <motion.div
        style={{
          position: 'fixed',
          top: 0,
          left: 100,
          right: 0,
          height: '4px',
          backgroundColor: '#FF005C',
          scaleX: scaleX,
          transformOrigin: '0%',
          z: 100000,
        }}
      />
      <Stack mx='auto' w='full' maxW={980} py={12} spacing={8}>
        <HStack
          gap={2}
          align='center'
          _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => navigate(-1)}
        >
          <NucArrowLeft />
          <Text>Volver atrás</Text>
        </HStack>

        <Image
          src={post.coverage_image_url ?? post.space.coverage_image_url}
          alt='Post Image'
          objectFit='cover'
          borderRadius='md'
          maxHeight='400px'
          maxWidth='100%'
        />
        <Heading fontSize='4xl' mb={10}>
          {post.name}
        </Heading>
        <Box>
          <ReactMarkdown
            unwrapDisallowed={true}
            remarkPlugins={[remarkGfm]}
            // @ts-ignore
            rehypePlugins={[rehypeRaw]}
            components={{
              iframe: ({ node, ...props }) => (
                <iframe
                  {...props}
                  title='embedded-content'
                  style={{ marginBottom: '24px' }}
                />
              ),
              a: ({ node, ...props }) => (
                <ChakraLink
                  {...props}
                  color='blue.400'
                  target='_blank'
                  referrerPolicy='no-referrer'
                  mb={4}
                />
              ),
              ul: ({ node, ...props }) => (
                <List styleType='disc' spacing={4} mb={6} {...props} />
              ),
              ol: ({ node, ...props }) => (
                <List styleType='decimal' spacing={4} mb={6} {...props} />
              ),
              li: ({ node, ...props }) => <ListItem mb={3} {...props} />,
              strong: ({ node, ...props }) => (
                <Text as='strong' fontWeight='bold' mb={4} {...props} />
              ),
              em: ({ node, ...props }) => (
                <Text as='em' fontStyle='italic' mb={4} {...props} />
              ),
              h2: ({ node, ...props }) => (
                <Heading as='h2' fontSize={24} mt={8} mb={6} {...props} />
              ),
              hr: ({ node, ...props }) => (
                <hr style={{ marginBottom: '24px' }} {...props} />
              ),
              p: ({ node, ...props }) => <Text mb={6} {...props} />,
              code: ({ node, ...props }) => (
                <Stack w='fit-content' mb={6}>
                  <Box
                    as='code'
                    p={2}
                    textAlign='center'
                    bg='#1f2124'
                    color='violet'
                    borderRadius='md'
                    {...props}
                  />
                </Stack>
              ),
              img: ({ node, ...props }) => (
                <Image
                  {...props}
                  my={6}
                  borderRadius='md'
                  alt={'Post image'}
                  objectFit='contain'
                />
              ),
            }}
          >
            {processedMarkdown}
          </ReactMarkdown>
        </Box>
      </Stack>
    </>
  )
}
