import { CACHE_TIME } from '@/shared/constants'
import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { gql } from 'graphql-request'

type GetGroupsResponse = {
  groups: {
    name: string
    slug: string
    spaces: {
      name: string
      slug: string
      coverage_image_url: string
    }[]
  }[]
}

type GetSpacePostsResponse = {
  posts_connection: {
    nodes: {
      name: string
      slug: string
      circle_published_at: string
      coverage_image_url: string
    }[]
    pageInfo: {
      total: number
    }
  }
}

type GetPostDetailResponse = {
  posts: {
    name: string
    body: string
    coverage_image_url: string
    space: {
      coverage_image_url: string
    }
  }[]
}

export const blogQueryApi = createApi({
  reducerPath: 'blogQueryApi',
  baseQuery: graphqlRequestBaseQuery({
    url: 'https://nucba-blog-prod-6trme.ondigitalocean.app/graphql',
  }),
  keepUnusedDataFor: CACHE_TIME,

  endpoints: builder => ({
    getGroups: builder.query<GetGroupsResponse, void>({
      query: () => ({
        document: gql`
          query getGroups {
            groups {
              name
              slug
              spaces {
                name
                slug
                coverage_image_url
              }
            }
          }
        `,
      }),
    }),
    getPosts: builder.query<
      GetSpacePostsResponse,
      { slug: string; page: number }
    >({
      query: (variables: { slug: string; page: number }) => ({
        document: gql`
          query getPosts($slug: String!, $page: Int!) {
            posts_connection(
              filters: { space: { slug: { eq: $slug } } }
              pagination: { pageSize: 18, page: $page }
              sort: ["circle_published_at:desc"]
            ) {
              nodes {
                name
                slug
                circle_published_at
                coverage_image_url
              }
              pageInfo {
                total
              }
            }
          }
        `,
        variables: {
          ...variables,
          slug: variables.slug.toLowerCase(),
        },
      }),
    }),
    getPostDetail: builder.query<
      GetPostDetailResponse,
      { spaceSlug: string; postSlug: string }
    >({
      query: ({ spaceSlug, postSlug }) => ({
        document: gql`
          query getPostDetail($spaceSlug: String!, $postSlug: String!) {
            posts(
              filters: {
                space: { slug: { eq: $spaceSlug } }
                slug: { eq: $postSlug }
              }
            ) {
              name
              body
              coverage_image_url
              space {
                coverage_image_url
              }
            }
          }
        `,
        variables: {
          spaceSlug: spaceSlug.toLowerCase(),
          postSlug: postSlug.toLowerCase(),
        },
      }),
    }),
  }),
})

export const { useGetGroupsQuery, useGetPostsQuery, useGetPostDetailQuery } =
  blogQueryApi
