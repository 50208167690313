import React, { useEffect, useRef } from 'react'
import {
  Stack,
  Grid,
  Text,
  GridItem,
  Heading,
  HStack,
  Button,
} from '@chakra-ui/react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { SectionPicker } from '../components/SectionPicker'
import { CardPost } from '../components/CardPost'
import {
  useGetPostsQuery,
  useGetGroupsQuery,
} from '../services/blog-query.service'
import { PostCardLoader } from '@/education/shared/components/loaders/BlogCardsLoader'
import styled from '@emotion/styled'
import { SectionPickerLoader } from '@/education/shared/components/loaders/SectionPickLoader'
import { NucArrowLeft, NucArrowRight } from '@nucba-coding/nucba-ui'

export const SectionBlogContainerWithScroll = styled(Stack)`
  width: 95%;
  height: 90vh;
  margin: 0.5rem 0;
  padding-right: 1rem;

  overflow-y: ${({ overflowY }) => overflowY || 'scroll'};

  &::-webkit-scrollbar {
    width: 8px;
    margin-left: 3rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #17181e;
    border-radius: 30px;
  }
`

const POSTS_PER_PAGE = 18

export const Blog = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const spaceSlug = searchParams.get('section') || 'html'
  const page = Number(searchParams.get('page') || '1')

  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0 })
    }
  }, [spaceSlug, page])

  const {
    data: postsData,
    isLoading,
    error,
  } = useGetPostsQuery({ slug: spaceSlug, page })
  const { data: groupsData, isLoading: isLoadingGroups } = useGetGroupsQuery()

  const isLastPage = (): boolean => {
    const totalItems =
      postsData?.posts_connection.pageInfo.total || POSTS_PER_PAGE

    return page * POSTS_PER_PAGE >= totalItems
  }

  const spaceImage = groupsData?.groups
    .flatMap(group => group.spaces)
    .find(space => space.slug === spaceSlug)?.coverage_image_url

  const handlePick = (label: string) => {
    navigate(`/blog?section=${label}&page=1`)
  }

  useEffect(() => {
    if (groupsData) {
      const validSections = groupsData.groups.flatMap(group =>
        group.spaces.map(space => space.slug),
      )

      if (spaceSlug && !validSections.includes(spaceSlug)) {
        navigate(`/blog?section=html`)
      }
    }
  }, [spaceSlug, groupsData])

  if (error) return <Text>Error: {error.toString()}</Text>

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      pl={20}
      gap={12}
      w='100%'
      overflowX='hidden'
      alignItems='center'
      justifyContent='center'
      minHeight='100vh'
    >
      <Stack spacing={6} w={{ base: '100%', md: 'auto' }}>
        {isLoadingGroups ? (
          <SectionPickerLoader />
        ) : (
          <SectionPicker
            onPick={handlePick}
            activeSection={spaceSlug}
            groups={groupsData?.groups || []}
          />
        )}
      </Stack>

      <Stack w={{ base: '100%' }} overflow='hidden'>
        <SectionBlogContainerWithScroll
          ref={containerRef}
          w={{ base: '100%', md: '95%' }}
          spacing={4}
        >
          <Heading size='xl'>
            {
              groupsData?.groups
                .find(group =>
                  group.spaces.some(space => space.slug === spaceSlug),
                )
                ?.spaces.find(space => space.slug === spaceSlug)?.name
            }
          </Heading>
          <Grid
            templateColumns={{
              base: '1fr',
              sm: 'repeat(auto-fill, minmax(250px, 1fr))',
            }}
            gap={6}
            w='100%'
            maxW='100%'
          >
            {isLoading ? (
              [...Array(6)].map((_, idx) => <PostCardLoader key={idx} />)
            ) : postsData?.posts_connection.nodes.length ? (
              postsData.posts_connection.nodes.map(post => (
                <CardPost
                  key={post.slug}
                  title={post.name}
                  date={post.circle_published_at}
                  coverageImage={post.coverage_image_url ?? spaceImage}
                  postSlug={post.slug}
                  spaceSlug={spaceSlug}
                />
              ))
            ) : (
              <GridItem colSpan={3}>
                <Text textAlign='center'>
                  Todavía no hay notas en la sección: {spaceSlug} 🥲
                </Text>
              </GridItem>
            )}
          </Grid>

          <HStack spacing={8} justify='center'>
            <Button
              as={Link}
              _disabled={{ opacity: 0.7, cursor: 'not-allowed' }}
              to={`/blog?section=${spaceSlug}&page=${page - 1}`}
              isDisabled={page === 1}
              onClick={e => {
                if (page === 1) {
                  e.preventDefault()
                }
              }}
            >
              <HStack align='baseline'>
                <NucArrowLeft width={12} height={12} />
                <Text fontSize={18}>Prev</Text>
              </HStack>
            </Button>

            <Button
              as={Link}
              _disabled={{ opacity: 0.7, cursor: 'not-allowed' }}
              to={`/blog?section=${spaceSlug}&page=${page + 1}`}
              isDisabled={isLastPage()}
              onClick={e => {
                if (isLastPage()) {
                  e.preventDefault()
                }
              }}
            >
              <HStack align='baseline'>
                <Text fontSize={18}>Next</Text>
                <NucArrowRight width={12} height={12} />
              </HStack>
            </Button>
          </HStack>
        </SectionBlogContainerWithScroll>
      </Stack>
    </Stack>
  )
}
