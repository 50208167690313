import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Heading, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'

import { slugifyCourseName } from '../../shared/utils'
import {
  useGetCohortNextAssignmentByStudentQuery,
  useGetCohortNextClassQuery,
  useGetCohortZoomIdQuery,
} from '../services/cohort.service'
import { useAssignmentPrefetch } from '@/education/assignments/services/assignment.service'
import { BannerLoader } from '@/education/shared/components/loaders/BannerLoader'
import { useStudent } from '@/shared/hooks/useStudent'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState, useSessionState } from '@/shared/features/session.slice'
import { useNucbaTheme } from '@/shared/hooks'
import { openInNewTab } from '@/shared/utils'
import { stringify } from 'qs'

type BannerProps = {
  bgImage: string
}

export const Banner = ({ bgImage }: BannerProps) => {
  const { colors } = useNucbaTheme()

  const navigate = useNavigate()
  const { code } = useParams()
  const { studentId } = useStudent(code!)

  const { isStaff, id, firstname, lastname } = useSessionState()

  const { data: zoomData } = useGetCohortZoomIdQuery({ code: code! })

  const { data: nextAssignment, isLoading: nextAssignmentIsLoading } =
    useGetCohortNextAssignmentByStudentQuery(
      { studentId, code: code! },
      {
        skip: isStaff,
      },
    )

  const { data: nextClass, isLoading: nextClassIsLoading } =
    useGetCohortNextClassQuery(code!, {
      skip: !isStaff,
    })

  const prefetchAssignmentDetails = useAssignmentPrefetch(
    'getAssignmentDetails',
    {
      force: false,
    },
  )

  return nextAssignmentIsLoading || nextClassIsLoading ? (
    <BannerLoader />
  ) : nextAssignment || nextClass ? (
    <Stack
      flexGrow={1}
      maxW='800px'
      h='full'
      justify='space-between'
      py={4}
      px={8}
      bg={`url(${bgImage}) center/cover no-repeat ,  linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 56.25%, #000000 92.64%)`}
      borderRadius={23}
    >
      <Stack>
        {isStaff ? (
          <Heading as='h3' fontSize={{ base: 'xl', '2xl': '3xl' }}>
            📋 Todavía no está disponible tu próxima clase 😣
          </Heading>
        ) : (
          <Heading display='flex' as='h3' fontSize={{ base: 'md', lg: 'xl' }}>
            Acceder a la clase 👉{' '}
            <Text
              as='span'
              cursor='pointer'
              onClick={() =>
                openInNewTab(
                  `https://us02web.zoom.us/j/${zoomData?.zoomId}?${stringify({
                    uname: `${firstname} ${lastname}`,
                  })}`,
                )
              }
            >
              Click aquí
            </Text>
          </Heading>
        )}
        {isStaff ? (
          <Text>En los próximos días se publicará tu próxima clase</Text>
        ) : (
          <VStack justify='start' alignItems='start'>
            <Text fontSize={{ base: 'xs', lg: 'xs' }}>
              🎓 ID ZOOM: {zoomData?.zoomId} | PASS ZOOM: La{code}
            </Text>
            <Text fontSize={{ base: 'xs', lg: 'xs' }}>
              🎓¿Tenes dudas? Ingresa al canal de discord de tu camada
            </Text>
            <Text fontSize={{ base: 'xs', lg: 'xs' }}>
              📍 Ingresa desde aquí al hub de entregas
            </Text>
          </VStack>
        )}

        <Button
          alignSelf='flex-end'
          paddingInline={5}
          fontSize='sm'
          size='xs'
          borderRadius={30}
          textTransform='uppercase'
          p={4}
          onClick={() => navigate(`./asignaciones/${studentId}`)}
        >
          Ir a mis entregas
        </Button>
      </Stack>
    </Stack>
  ) : (
    <Stack
      flexGrow={1}
      h='full'
      justify='space-around'
      paddingInline={8}
      bg={`url(${bgImage}) center/cover no-repeat, linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 56.25%, #000000 92.64%)`}
      borderRadius={23}
    >
      <Stack>
        {isStaff ? (
          <Heading as='h3' fontSize={{ base: 'xl', '2xl': '3xl' }}>
            📋 Todavía no está disponible tu próxima clase 😣
          </Heading>
        ) : (
          <Heading display='flex' as='h3' fontSize={{ base: 'md', lg: 'xl' }}>
            Acceder a la clase 👉{' '}
            <Text
              as='span'
              cursor='pointer'
              _hover={{
                textDecoration: 'underline',
              }}
              onClick={() =>
                openInNewTab(
                  `https://us02web.zoom.us/j/${zoomData?.zoomId}?${stringify({
                    uname: `${firstname} ${lastname}`,
                  })}`,
                )
              }
            >
              Click aquí
            </Text>
          </Heading>
        )}
        {isStaff ? (
          <Text>En los próximos días se publicará tu próxima clase</Text>
        ) : (
          <VStack justify='start' alignItems='start'>
            <Text fontSize={{ base: 'xs', lg: 'xs' }}>
              🎓 ID ZOOM: {zoomData?.zoomId} | PASS ZOOM: La{code}
            </Text>
            <Text fontSize={{ base: 'xs', lg: 'xs' }}>
              🎓¿Tenes dudas? Ingresa al canal de discord de tu camada
            </Text>
            <Text fontSize={{ base: 'xs', lg: 'xs' }}>
              📍 Ingresa desde aquí al hub de entregas
            </Text>
          </VStack>
        )}

        <Button
          alignSelf='flex-end'
          paddingInline={5}
          fontSize='sm'
          size='xs'
          borderRadius={30}
          textTransform='uppercase'
          p={4}
          onClick={() => navigate(`./asignaciones/${studentId}`)}
        >
          Ir a mis entregas
        </Button>
      </Stack>
    </Stack>
  )
}
